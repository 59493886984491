import useAxios from '@repo/hooks/useAxios';
import { useEffect, useMemo, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Divider, List, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatDrawerLinks } from './drawerLinksConfig';
import renderDrawerLink from './renderDrawerLink';
import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';
import { getFetcherConfig } from '@repo/config/authUtils';
import { useQuery } from '@tanstack/react-query';
import { getBooksURL, getChaptersURL } from '@repo/config/apps/wiki/routes';
import { getCalendarAccountsURL } from '@repo/config/apps/calendar/routes';
import { getImapAccountsURL } from '@repo/config/apps/communication/routes';

interface DrawerProps {
  mobileOpen: boolean;
  laptopOpen: boolean;
  drawerWidth: number;
  drawerLinks: any[];
  handleDrawerToggle: () => void;
}

export default function Drawer({
  mobileOpen,
  laptopOpen,
  drawerWidth,
  drawerLinks,
  handleDrawerToggle,
}: DrawerProps) {
  const axios = useAxios();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { roleNames, accessToken } = useAppSelector(selectProfile);
  const [additionalLinks, setAdditionalLinks] = useState({
    booksList: [],
    calendarAccounts: [],
    imapAccounts: { connectedAccts: [], disconnectedAccts: [] },
  });

  const {
    isPending: isLoadingBooks,
    isError: isErrorBooks,
    data: booksData,
  } = useQuery({
    queryKey: ['drawer-wiki-books', accessToken],
    queryFn: async () => {
      const { data } = await axios.get(
        getBooksURL({
          query: '?page_size=25&published=true',
        }),
        getFetcherConfig({ token: accessToken })
      );
      return data;
    },
    enabled: !!accessToken,
  });

  const {
    isPending: isLoadingChapters,
    isError: isErrorChapters,
    data: chaptersData,
  } = useQuery({
    queryKey: ['drawer-wiki-book-chapters', accessToken],
    queryFn: async () => {
      const { data } = await axios.get(
        getChaptersURL({
          query: '?page_size=500&ordering=order',
        }),
        getFetcherConfig({ token: accessToken })
      );
      return data;
    },
    enabled: !!accessToken,
  });

  const {
    isPending: isLoadingCalendars,
    isError: isErrorCalendars,
    data: calendarsData,
  } = useQuery({
    queryKey: ['drawer-user-calendars', accessToken],
    queryFn: async () => {
      const { data } = await axios.get(
        getCalendarAccountsURL({
          query: '?page_size=35&primary=true',
        }),
        getFetcherConfig({ token: accessToken })
      );
      return data;
    },
    enabled: !!accessToken,
  });

  const {
    isPending: isLoadingImapAccounts,
    isError: isErrorImapAccounts,
    data: imapAccountsData,
  } = useQuery({
    queryKey: ['drawer-user-imap-accounts', accessToken],
    queryFn: async () => {
      const { data } = await axios.get(
        getImapAccountsURL({
          query: '?page_size=25',
        }),
        getFetcherConfig({ token: accessToken })
      );
      return data;
    },
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (
      isErrorBooks ||
      isLoadingBooks ||
      isErrorChapters ||
      isLoadingChapters ||
      !booksData ||
      !chaptersData
    ) {
      return;
    }

    const booksList = booksData?.results?.map((book: any) => ({
      id: book?.id,
      label: book?.name,
      href: '',
      children: chaptersData?.results
        ?.filter((chapter: any) => book?.id === chapter?.book)
        ?.map((chapter: any) => ({
          id: chapter?.id,
          label: chapter?.name,
          href: `/svc/wiki/${chapter?.id ?? ''}`,
          children: [],
        })),
    }));

    setAdditionalLinks((prevState) => ({ ...prevState, booksList }));
  }, [isErrorChapters, isLoadingChapters, chaptersData]);

  useEffect(() => {
    if (isErrorCalendars || isLoadingCalendars || !calendarsData) {
      return;
    }

    const calendarAccounts = calendarsData?.results.map((calendar: any) => ({
      id: calendar?.id,
      label: calendar?.email,
      href: '',
      children: [
        ...(calendar?.calendars ?? []).map((child: any) => ({
          id: child?.id,
          label: child?.name,
          href: `/svc/calendar/${child.id}`,
          children: [],
        })),
      ],
    }));

    setAdditionalLinks((prevState) => ({ ...prevState, calendarAccounts }));
  }, [isLoadingCalendars, isErrorCalendars, calendarsData]);

  useEffect(() => {
    if (isErrorImapAccounts || isLoadingImapAccounts || !imapAccountsData) {
      return;
    }

    const { connectedAccts, disconnectedAccts } = (
      imapAccountsData?.results || []
    ).reduce(
      (acc: any, { display_name, username, id, connected }: any) => {
        const label = display_name || username;

        const children = [
          {
            id: '325d10a1-267a-4a38-bea4-1e9ca2e4e714',
            label: 'Inbox',
            href: `/svc/inbox-emails/${id}`,
            children: [],
          },
          {
            id: 'f8230d0b-13ef-4af1-ad59-647a463add8d',
            label: 'Sent',
            href: `/svc/sent-emails/${id}`,
            children: [],
          },
          {
            id: '70121693-e474-43bc-8f24-e183ed7fb1eb',
            label: 'Signature',
            href: `/svc/signature/${id}`,
            children: [],
          },
        ];

        const acctObj = { id, label, href: '', children };
        connected
          ? acc.connectedAccts.push(acctObj)
          : acc.disconnectedAccts.push(acctObj);

        return acc;
      },
      { connectedAccts: [], disconnectedAccts: [] }
    );

    setAdditionalLinks((prevState) => ({
      ...prevState,
      imapAccounts: { connectedAccts, disconnectedAccts },
    }));
  }, [isErrorImapAccounts, isLoadingImapAccounts, imapAccountsData]);

  const finalDrawerLinks = useMemo(() => {
    const links = formatDrawerLinks(drawerLinks, roleNames);

    // Update "Wiki" with "Books" children
    const wikiLink = links?.live?.find((link: any) => link?.label === 'Wiki');

    if (wikiLink) {
      wikiLink.children = (wikiLink?.children ?? []).map((child: any) => {
        if (child.label === 'Books') {
          return {
            ...child,
            children: additionalLinks?.booksList ?? [],
          };
        }
        return child;
      });
    }

    // Update "Calendar" with "Calendars" children
    const calendarLink = links?.live?.find(
      (link: any) => link?.label === 'Calendar'
    );

    if (calendarLink) {
      calendarLink.children = (calendarLink?.children ?? []).map(
        (child: any) => {
          if (child.label === 'Calendars') {
            return {
              ...child,
              children: additionalLinks?.calendarAccounts ?? [],
            };
          }
          return child;
        }
      );
    }

    // Update "Communication" with "Emails" children
    const communicationLink = links?.live?.find(
      (link: any) => link?.label === 'Communication'
    );

    if (communicationLink) {
      communicationLink.children = (communicationLink?.children ?? []).map(
        (child: any) => {
          if (child.label === 'Emails') {
            return {
              ...child,
              children: [
                {
                  id: '60f2d71c-005d-4080-b368-3033beedb4c3',
                  label: 'All Emails',
                  href: '/svc/all-emails',
                  children: [],
                },
                ...(additionalLinks?.imapAccounts?.connectedAccts ?? []),
                {
                  id: '336578ed-7a5d-44bd-9d23-21d929300f8c',
                  label: 'Disconnected Accounts',
                  href: '',
                  children: [
                    ...(additionalLinks?.imapAccounts?.disconnectedAccts ?? []),
                  ],
                },
              ],
            };
          }
          return child;
        }
      );
    }

    return links;
  }, [roleNames, additionalLinks]);

  const drawer = useMemo(
    () => (
      <div>
        <Toolbar variant='dense' />

        <Divider />

        <List>
          {finalDrawerLinks?.live?.map((link) => {
            return renderDrawerLink({ link });
          })}
        </List>

        {finalDrawerLinks?.live?.length &&
        finalDrawerLinks?.unstable?.length ? (
          <Divider />
        ) : null}

        <List>
          {finalDrawerLinks?.unstable?.map((link) => {
            return renderDrawerLink({ link });
          })}
        </List>

        {finalDrawerLinks?.unstable?.length &&
        finalDrawerLinks?.createdByCompute?.length ? (
          <Divider sx={{ borderBottomWidth: 'medium' }} />
        ) : null}

        <List>
          {finalDrawerLinks?.createdByCompute?.map((link) => {
            return renderDrawerLink({ link });
          })}
        </List>
      </div>
    ),
    [finalDrawerLinks]
  );

  const drawerStyles = {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  };

  return (
    <Box
      component='nav'
      sx={{
        display: laptopOpen ? '' : 'none',
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label='mailbox folders'
    >
      {isMobile ? (
        <MuiDrawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            ...drawerStyles,
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {drawer}
        </MuiDrawer>
      ) : null}

      {!isMobile ? (
        <MuiDrawer
          variant='persistent'
          sx={{
            ...drawerStyles,
            display: { xs: 'none', sm: 'block' },
          }}
          open={laptopOpen}
        >
          {drawer}
        </MuiDrawer>
      ) : null}
    </Box>
  );
}
