import axios from 'axios';
import { getTokenRefreshURL } from '../../apps/main-proxy/routes';

export type UserDetail = {
  userId: string;
  username: string | null;
  email: string;
  isSuperUser: boolean;
  personId: string | null;
  client: string;
};

interface FetcherConfigParams {
  token?: string;
  actAsClientId?: string;
}

interface FetcherConfig {
  headers: {
    Authorization: string;
    ActAs?: string;
  };
}

interface DepartmentHead {
  id?: string;
  label?: string;
}

interface EmployeeDetails {
  id?: string;
  department_heads?: DepartmentHead;
}

interface PersonDetails {
  id?: string;
}

interface ClientDetails {
  id?: string;
  client_options?: Record<string, any>; // Key-value pairs
}

interface UserData {
  id?: string;
  username?: string;
  email?: string;
  details?: {
    person?: PersonDetails;
    employee?: EmployeeDetails;
  };
  client?: ClientDetails;
  roleNames?: string[];
}

interface FormattedUserDetails {
  userId: string;
  username: string;
  email: string;
  isSuperUser: boolean;
  personId: string;
  employeeId: string;
  employeeSupervisorId: string;
  employeeSupervisorName: string;
  client: string;
  roleNames: string[];
  adminLogo: string | null;
  favicon: string | null;
  brandColor: string;
}

export const refreshAccessToken = async (
  csrfToken: string
): Promise<any | null> => {
  try {
    const response = await axios.post(
      getTokenRefreshURL(),
      {},
      {
        headers: {
          'x-csrf-token': csrfToken, // Pass the CSRF token in headers
        },
        withCredentials: true,
      }
    );

    if (response.data?.success && response.data?.data?.accessToken) {
      console.log('Access token refreshed successfully');
      return response.data?.data;
    } else {
      console.error('Failed to refresh access token: No token in response');
      return null;
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

// Helper function to validate user details
export const formatUserDetails = (data: UserData): FormattedUserDetails => {
  return {
    userId: `${data?.id ?? ''}`,
    username: `${data?.username ?? ''}`,
    email: `${data?.email ?? ''}`,
    isSuperUser: false,
    personId: `${data?.details?.person?.id ?? ''}`,
    employeeId: `${data?.details?.employee?.id ?? ''}`,
    employeeSupervisorId: `${data?.details?.employee?.department_heads?.id ?? ''}`,
    employeeSupervisorName: `${data?.details?.employee?.department_heads?.label ?? ''}`,
    client: `${data?.client?.id ?? ''}`,
    roleNames: data?.roleNames ?? [],
    adminLogo: data?.client?.client_options?.adminLogo?.value ?? null,
    favicon: data?.client?.client_options?.favicon?.value ?? null,
    brandColor: data?.client?.client_options?.primaryColor?.value ?? null,
  };
};

export function getFetcherConfig({
  token,
  actAsClientId,
}: FetcherConfigParams) {
  const config: FetcherConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (actAsClientId) {
    config.headers.ActAs = actAsClientId;
  }

  return config;
}
