export const ADMIN_LOGO = 'Pullstream admin';

export const COLORS = {
  primary: '#4792dd',
  light: '#f7f7f8',
  background: '#f8fbff',
  dark: '#1B1F33',
  drawerLight: '#999',
  linkActive: '#EEE',
};

export const ACCOUNTS_NODE_CSRF_SESSION = `${process.env.NEXT_PUBLIC_APP_ENV}-12071e0ab2`;

export const DURATION_UNIT_CHOICES = [
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Minutes',
    value: 'minutes',
  },
];

export const TOAST_CONTAINER_PROPS = {
  position: 'top-center',
  autoClose: 5000,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  hideProgressBar: true,
  theme: 'dark',
};

export const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};
