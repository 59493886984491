import { useEffect, useMemo, useRef } from 'react';
import { ACCOUNTS_NODE_CSRF_SESSION } from '@repo/config/constants';
import { windowRedirect } from '@repo/config/generalUtils';
import { formatUserDetails, refreshAccessToken } from '@repo/config/authUtils';
import { useAppDispatch, useAppSelector } from '@repo/redux/hooks';
import { selectProfile, setProfile } from '@repo/redux/profileSlice';
import axios, { AxiosInstance } from 'axios';

export default function useAxios(): AxiosInstance {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectProfile);

  const isRefreshing = useRef(false);
  const refreshSubscribers = useRef<Array<(newToken: string) => void>>([]);

  const apiClient = useMemo(() => axios.create(), []);

  const redirectToSignIn = () => {
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;
    const source = encodeURIComponent(`${currentPath}${currentQuery}`);
    windowRedirect(`/accounts/auth/sign-in?n=${source}`);
  };

  useEffect(() => {
    const notifySubscribers = (newToken: string) => {
      refreshSubscribers.current.forEach((callback) => callback(newToken));
      refreshSubscribers.current = [];
    };

    const handleTokenRefresh = async (csrfToken: string) => {
      try {
        const newToken = await refreshAccessToken(csrfToken);
        if (!newToken?.accessToken) throw new Error('Token refresh failed');

        const newUserDetails = formatUserDetails(newToken?.userInfo);

        dispatch(
          setProfile({
            ...user,
            accessToken: newToken.accessToken,
            ...newUserDetails,
          })
        );

        notifySubscribers(newToken.accessToken);
      } catch (error) {
        console.error('Token refresh failed:', error);
        redirectToSignIn(); // Redirect to sign-in with the source path
      } finally {
        isRefreshing.current = false;
      }
    };

    const interceptor = apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;

        // Check for specific properties in the response data
        const shouldRefresh =
          response?.data?.message === 'Invalid token!' ||
          response?.data?.status === '401' ||
          response?.data?.statusCode === 401 ||
          response?.data?.code === 'token_not_valid';

        if (
          (error.response?.status === 403 || error.response?.status === 401) &&
          shouldRefresh
        ) {
          console.warn(
            '403 Forbidden error detected, attempting token refresh...'
          );

          const csrfToken = localStorage.getItem(ACCOUNTS_NODE_CSRF_SESSION);

          if (!csrfToken) {
            console.error('No CSRF token found. Redirecting to login page.');
            redirectToSignIn(); // Redirect to sign-in with the source path
            return;
          }

          if (!isRefreshing.current) {
            isRefreshing.current = true;
            handleTokenRefresh(csrfToken);
          }

          return new Promise((resolve) => {
            refreshSubscribers.current.push((newToken) => {
              error.config.headers['Authorization'] = `Bearer ${newToken}`;
              resolve(apiClient.request(error.config));
            });
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      apiClient.interceptors.response.eject(interceptor);
    };
  }, [apiClient, dispatch, user]);

  return apiClient;
}
