export const getSignInURL = ({ query = '' } = {}) => {
  return `/api/sign-in/${query}`;
};

export const getSignOutURL = ({ query = '' } = {}) => {
  return `/api/sign-out/${query}`;
};

export const getTokenRefreshURL = ({ query = '' } = {}) => {
  return `/api/refresh/${query}`;
};
