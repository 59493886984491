import moment from 'moment';
import { Message, ReplyTo, Room } from '../typings/chatTypes';

export interface SendMessageProp {
  message: string;
  attachmentId?: string;
}

export const sortMessages = (a: Message, b: Message) => {
  return new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime();
};

const getTime = (date: string) => new Date(date).getTime();

/**
 * Formats a UTC datetime string for display in a chat application.
 *
 * @param {string} utcDateString - The UTC datetime string to format.
 * @return {string} - The formatted date string.
 */
export const formatChatMessageDate = (utcDateString: string, long = false) => {
  const messageTime = moment(utcDateString);
  const now = moment();
  let formatString;

  // Determine the appropriate format based on how old the message is
  if (messageTime.isSame(now, 'day')) {
    formatString = 'h:mm a'; // Time only for same day
  } else {
    formatString = `DD MMM ${long ? 'h:mm a' : ''}`; // Full date for different year
  }

  // Convert UTC to local time and format
  return messageTime.local().format(formatString);
};

export const truncateContent = (text: string, maxLength = 150) => {
  return text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const getPersonNameAndInitials = (person: any) => {
  const isValidString = (value: string) =>
    typeof value === 'string' && value.trim() !== '';

  const first_name = isValidString(person?.first_name) ? person.first_name : '';
  const last_name = isValidString(person?.last_name) ? person.last_name : '';

  const fullName = `${first_name} ${last_name}`.trim();
  const initials = `${first_name.charAt(0)}${last_name.charAt(0)}`.trim();

  return { fullName, initials };
};

export const compareRoomsByLatestMessageOrCreationTime = (a: Room, b: Room) => {
  // Retrieve the latest messages from both rooms
  const lastMessageA = a?.roomMessages?.[0]?.createdAt;
  const lastMessageB = b?.roomMessages?.[0]?.createdAt;

  // Get the timestamps of the latest messages or use Infinity if there are no messages
  const aLatestMessageTime = lastMessageA ? getTime(lastMessageA) : Infinity;
  const bLatestMessageTime = lastMessageB ? getTime(lastMessageB) : Infinity;

  // Get the room creation times
  const aCreatedAtTime = getTime(a.createdAt);
  const bCreatedAtTime = getTime(b.createdAt);

  // Case 1: If both rooms have no messages, sort by the createdAt date of the rooms
  if (aLatestMessageTime === Infinity && bLatestMessageTime === Infinity) {
    return bCreatedAtTime - aCreatedAtTime;
  }

  // Case 2: If room 'a' has no messages
  if (aLatestMessageTime === Infinity) {
    // Compare room 'a' createdAt with the latest message time of room 'b'
    return aCreatedAtTime > bLatestMessageTime ? -1 : 1;
  }

  // Case 3: If room 'b' has no messages
  if (bLatestMessageTime === Infinity) {
    // Compare room 'b' createdAt with the latest message time of room 'a'
    return bCreatedAtTime > aLatestMessageTime ? 1 : -1;
  }

  // Case 4: If both rooms have messages, sort by the latest message's createdAt time
  return bLatestMessageTime - aLatestMessageTime;
};

export const getReplyToAuthorName = (
  replyTo: ReplyTo | null,
  userMemberId: string
) => {
  // Extract the author ID from the replyTo object
  const replyToAuthorId = replyTo?.authorId;

  // If there is no authorId, return null
  if (!replyToAuthorId) {
    return null;
  }

  // If the reply is from the current user, return 'You'
  if (replyToAuthorId === userMemberId) {
    return 'You';
  }

  const { fullName } = getPersonNameAndInitials(
    replyTo?.author?.details?.person
  );

  // If the fullName is found, return their full name
  if (fullName) {
    return fullName;
  }

  // If the author is not found, return null
  return null;
};

export const showNotification = (
  title: string,
  options: NotificationOptions
) => {
  if (Notification.permission === 'granted') {
    try {
      new Notification(title, options);
    } catch (error) {
      console.error('Failed to show notification:', error);
    }
  }
};

export const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notification');
    return;
  }

  if (Notification.permission === 'granted') {
    return true;
  }

  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }

  return false;
};

export const ensureSocketConnected = (socket: any) => {
  if (!socket?.connected) {
    socket.connect();
  }
};

/**
 * Joins a chat room using its ID.
 * @param {string} chatRoomId ID of the chat room to join
 */
export const joinChatRoom = (socket: any, chatRoomId: string) => {
  console.log('Joining chat room:', chatRoomId);
  ensureSocketConnected(socket);
  socket.emit('joinRoom', { roomId: chatRoomId });
};

export const getNotificationAudio = () => {
  return typeof Audio !== 'undefined'
    ? new Audio(
        'https://drive.pullstream.com/file/5926643d-95f3-406a-80c4-ea0df6d261de.ogg'
      )
    : null;
};
