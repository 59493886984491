import { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DEFAULT_QUERY_CLIENT_OPTIONS } from '@repo/config/constants';

import AppBar from '../AppBar/AppBar';
import Drawer from '../Drawer/Drawer';

const DRAWER_WIDTH = 240;

interface AdminLayoutProps {
  children: React.ReactNode;
  drawerLinks: any[];
}

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS);

export default function AdminLayout({
  children,
  drawerLinks,
}: AdminLayoutProps) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [laptopOpen, setLaptopOpen] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen((mo) => !mo);
    setLaptopOpen((lo) => !lo);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar handleDrawerToggle={handleDrawerToggle} />
        <Drawer
          laptopOpen={laptopOpen}
          drawerWidth={DRAWER_WIDTH}
          mobileOpen={mobileOpen}
          drawerLinks={drawerLinks}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              sm: laptopOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
            },
          }}
        >
          <Toolbar variant='dense' />
          {children}
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
