import React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@repo/config/theme';
import AdminLayout from '@repo/ui/AdminLayout';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from '@repo/redux/store';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DEFAULT_QUERY_CLIENT_OPTIONS,
  TOAST_CONTAINER_PROPS,
} from '@repo/config/constants';
import { Box, CircularProgress } from '@mui/material';
import useAuth from '@repo/hooks/useAuth';

const noAdminLayoutRoutes = [
  '/auth/sign-in',
  '/auth/sign-up',
  '/auth/forgot-password',
  '/auth/reset-password/[token]',
  '/404',
];

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS);

function App(props: AppProps) {
  const router = useRouter(); // Use the useRouter hook to get current route info

  const { Component, pageProps } = props;
  const { isProtected, domain } = pageProps;

  const useAdminLayout = !noAdminLayoutRoutes.includes(router.pathname);
  const { isUserInfoInitialized } = useAuth(domain, isProtected);

  console.log('pageProps', pageProps);

  if (!isUserInfoInitialized && isProtected) {
    // Render a loading indicator while user info is being initialized
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <QueryClientProvider client={queryClient}>
          {useAdminLayout ? (
            <AdminLayout drawerLinks={pageProps?.drawerLinks ?? []}>
              <Component {...pageProps} />
            </AdminLayout>
          ) : (
            <Component {...pageProps} />
          )}

          <ToastContainer {...(TOAST_CONTAINER_PROPS as ToastContainerProps)} />
        </QueryClientProvider>
      </ThemeProvider>
    </AppCacheProvider>
  );
}

export default function AppWrapper(props: AppProps) {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
}
