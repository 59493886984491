import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ACCOUNTS_NODE_CSRF_SESSION } from '@repo/config/constants';
import { getSignOutURL } from '@repo/config/apps/main-proxy/routes';
import {
  getUsernameAndInitials,
  windowRedirect,
} from '@repo/config/generalUtils';
import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';
import SignOutConfirmationPopup from '../SignOutConfirmationPopup/SignOutConfirmationPopup';
import axios from 'axios';

export default function UserAccountMenu() {
  const { domain, ...userDetails } = useAppSelector(selectProfile);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false); // State for the dialog

  const { username } = getUsernameAndInitials(
    userDetails?.username,
    userDetails?.email
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = async (signOutType: 'one' | 'all') => {
    try {
      const csrfToken = localStorage.getItem(ACCOUNTS_NODE_CSRF_SESSION);

      // Make the API request
      await axios.post(
        getSignOutURL(),
        { all: signOutType !== 'one' }, // Pass signOut type as payload
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${userDetails?.accessToken}`,
            'x-csrf-token': csrfToken,
          },
        }
      );

      // Clear local storage and redirect
      localStorage.removeItem(ACCOUNTS_NODE_CSRF_SESSION);
      windowRedirect('/accounts/auth/sign-in');
    } catch (error) {
      console.error('Error during signOut:', error);

      // Ensure local storage is cleared even in case of an error
      localStorage.removeItem(ACCOUNTS_NODE_CSRF_SESSION);
      windowRedirect('/accounts/auth/sign-in');
    }
  };

  const handleSignOutClick = () => {
    setAnchorEl(null);
    setSignOutDialogOpen(true); // Open the dialog box
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2, color: '#fff' }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Tooltip title={`Account: ${username}`}>
            <AccountCircleIcon />
          </Tooltip>
          <Typography sx={{ ml: 1, color: '#fff' }}>{username}</Typography>
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          '.MuiPaper-root': {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
      </Menu>

      <SignOutConfirmationPopup
        open={signOutDialogOpen}
        setOpen={setSignOutDialogOpen}
        onConfirmSignOutAll={() => onSignOut('all')} // Sign out from all devices
        onConfirmSignOutCurrent={() => onSignOut('one')} // Sign out from this device
      />
    </>
  );
}
