import { NextApiRequest } from 'next';
import { snakeCase } from 'lodash';

type Payload = { [key: string]: any };

export function windowRedirect(path: string = '/') {
  window.location.href = path;
}

export function formatPayloadToSnakeCase(values: Payload) {
  return Object.keys(values).reduce((acc: Payload, key: string) => {
    /**
     * Convert key to snake_case
     * If the value is an object with an 'id' property, send only the 'id'
     * Otherwise, send the full value
     */
    acc[snakeCase(key)] = values[key]?.id || values[key];
    return acc;
  }, {});
}

export function getRootDomainServer(req: NextApiRequest) {
  try {
    const proto = req.headers['x-forwarded-proto'];
    const host = req.headers['x-forwarded-host'];
    if (!host) return '';
    return `${proto ?? 'https'}://${host}`;
  } catch (error) {
    console.error('Error getting root domain on the server:', error);
    return '';
  }
}

export function getRootDomainClient() {
  if (typeof window === 'undefined' || !window.location) {
    return '';
  }

  return window.location.origin;
}

export function parseJSON(jsonString: string, defaultValue: any = []) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.warn('Failed to parse json', jsonString);
    return defaultValue;
  }
}

export function getUsernameAndInitials(username: string, email?: string) {
  // If the username is not provided, use the email (if it exists), stripping the part after '@'
  const finalUsername = username
    ? username.charAt(0).toUpperCase() + username.slice(1)
    : (email?.split('@')[0] || '').charAt(0).toUpperCase() +
      (email?.split('@')[0] || '').slice(1);

  // Get the initials, taking the first two letters if possible
  const initials = finalUsername.slice(0, 2).toUpperCase();

  return { username: finalUsername, initials };
}

function getValueFromPath(obj: any, path: string) {
  return path.split('.').reduce((acc, key) => {
    if (key.endsWith('?')) {
      key = key.slice(0, -1); // Remove '?' from the key
      return acc?.[key]; // Safely access the key using optional chaining
    }
    return acc?.[key]; // Use optional chaining to prevent errors
  }, obj);
}

export function getFormattedLabelValue(
  data: any,
  displayValue: string,
  formatLabel?: (value: any) => string
) {
  const isNestedDisplayValue = displayValue.includes('?.');
  const extractedLabel = data?.[displayValue];
  const nestedLabelValue = getValueFromPath(data, displayValue);
  const finalLabelValue = isNestedDisplayValue
    ? nestedLabelValue
    : extractedLabel;
  return formatLabel ? formatLabel(finalLabelValue) : String(finalLabelValue);
}
