import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { sortMessages } from '@repo/config/apps/chat/utils/chatUtils';
import {
  Room,
  ReplyTo,
  Message,
} from '@repo/config/apps/chat/typings/chatTypes';

export enum RoomStatus {
  Idle = 'idle',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

type TypingStatus = {
  [key: string]: {
    authorId: string;
    isTyping: boolean;
    roomId?: string;
  };
};

interface ActiveRoomState {
  status: RoomStatus;
  roomId: string | null;
}

interface ChatState {
  activeRoom: Room | null;
  replyTo: ReplyTo | null;
  activeRoomState: ActiveRoomState | null;
  userMemberId: string | null;
  shouldScroll: boolean;
  rooms: Room[];
  messages: Message[];
  typingStatus: TypingStatus;
  groupChatSettingsOpen: boolean;
  privateChatSettingsOpen: boolean;
}

const initialState: ChatState = {
  activeRoom: null,
  replyTo: null,
  activeRoomState: { status: RoomStatus.Idle, roomId: null },
  userMemberId: null,
  shouldScroll: false,
  rooms: [],
  messages: [],
  typingStatus: {},
  groupChatSettingsOpen: false,
  privateChatSettingsOpen: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveRoom: (state, action: PayloadAction<Room | null>) => {
      state.activeRoom = action.payload;
    },
    setGroupChatSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.groupChatSettingsOpen = action.payload;
    },
    setPrivateChatSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.privateChatSettingsOpen = action.payload;
    },
    setReplyTo: (state, action: PayloadAction<ReplyTo | null>) => {
      state.replyTo = action.payload;
    },
    setActiveRoomState: (
      state,
      action: PayloadAction<ActiveRoomState | null>
    ) => {
      state.activeRoomState = action.payload;
    },
    setUserMemberId: (state, action: PayloadAction<string | null>) => {
      state.userMemberId = action.payload;
    },
    setShouldScroll: (state, action: PayloadAction<boolean>) => {
      state.shouldScroll = action.payload;
    },
    setRooms: (state, action: PayloadAction<Room[]>) => {
      state.rooms = _.uniqBy(action.payload, 'id');
    },
    addRooms: (state, action: PayloadAction<Room[]>) => {
      state.rooms = _.uniqBy([...state.rooms, ...action.payload], 'id');
    },
    updateRoom: (
      state,
      action: PayloadAction<{ roomId: string; updates: Partial<Room> }>
    ) => {
      const clonedRooms = _.cloneDeep(state.rooms);

      const updatedRooms = clonedRooms.map((room: Room) => {
        if (room?.id === action.payload?.roomId) {
          return { ...room, ...action.payload?.updates };
        }
        return room;
      });

      state.rooms = _.uniqBy(updatedRooms, 'id');
    },
    removeRoom: (state, action: PayloadAction<{ roomId: string }>) => {
      const roomId = action.payload.roomId;
      const clonedRooms = _.cloneDeep(state.rooms);

      const updatedRooms = [
        ...clonedRooms.filter(({ id }: Room) => id !== roomId),
      ];

      state.rooms = updatedRooms;

      if (state?.activeRoom?.id === roomId) {
        state.activeRoom = null;
      }
    },
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = _.uniqBy(action.payload, 'id');
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages = _.uniqBy([...state.messages, action.payload], 'id');
    },
    updateMessage: (
      state,
      action: PayloadAction<{ messageId: string; updates: Partial<Message> }>
    ) => {
      // Helper function to apply updates to a message based on the payload
      const applyMessageUpdates = (
        message: Message,
        payload: { messageId: string; updates: Partial<Message> }
      ) => {
        if (message?.id === payload?.messageId) {
          return { ...message, ...payload?.updates };
        }
        return message;
      };

      const updatedMessages = state.messages
        .map((message) => applyMessageUpdates(message, action.payload))
        .sort(sortMessages);

      state.messages = _.uniqBy(updatedMessages, 'id');

      const updatedRooms = state.rooms.map((room) => {
        const updatedRoomMessages = room.roomMessages.map((message) =>
          applyMessageUpdates(message, action.payload)
        );

        return {
          ...room,
          roomMessages: _.uniqBy(updatedRoomMessages, 'id'),
        };
      });

      state.rooms = _.uniqBy(updatedRooms, 'id');
    },
    updateLatestRoomMessage: (state, action: PayloadAction<Message>) => {
      const clonedRooms = _.cloneDeep(state.rooms);

      const index = clonedRooms.findIndex(
        (room) => room.id === action.payload.roomId
      );

      if (index !== -1 && clonedRooms && clonedRooms[index]) {
        clonedRooms[index].roomMessages = [action.payload];
      }

      state.rooms = clonedRooms;
    },
    setTypingStatus: (state, action: PayloadAction<TypingStatus>) => {
      state.typingStatus = action.payload;
    },
    setRoomTypingStatus: (
      state,
      action: PayloadAction<{
        authorId: string;
        isTyping: boolean;
        roomId: string;
      }>
    ) => {
      const { roomId, isTyping, authorId } = action.payload;

      state.typingStatus[roomId] = { isTyping, authorId };
    },
  },
});

export const {
  updateRoom,
  setActiveRoom,
  setReplyTo,
  setActiveRoomState,
  setUserMemberId,
  setShouldScroll,
  setRooms,
  addRooms,
  removeRoom,
  setMessages,
  addMessage,
  updateMessage,
  setTypingStatus,
  setRoomTypingStatus,
  updateLatestRoomMessage,
  setGroupChatSettingsOpen,
  setPrivateChatSettingsOpen,
} = chatSlice.actions;

export const selectChat = (state: RootState) => state.chat;

export default chatSlice.reducer;
