import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface SignOutConfirmationPopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirmSignOutAll: () => void;
  onConfirmSignOutCurrent: () => void;
}

export default function SignOutConfirmationPopup({
  open,
  setOpen,
  onConfirmSignOutAll,
  onConfirmSignOutCurrent,
}: SignOutConfirmationPopupProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='signOut-dialog-title'
      aria-describedby='signOut-dialog-description'
    >
      <DialogTitle id='signOut-dialog-title'>Sign Out</DialogTitle>
      <DialogContent>
        <DialogContentText id='signOut-dialog-description'>
          Do you want to sign out from all devices or just this one?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onConfirmSignOutCurrent} color='primary'>
          This Device Only
        </Button>
        <Button onClick={onConfirmSignOutAll} color='error' autoFocus>
          All Devices
        </Button>
      </DialogActions>
    </Dialog>
  );
}
