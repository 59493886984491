import React from 'react';
import { useAppDispatch, useAppSelector } from '@repo/redux/hooks';
import { selectProfile, setProfile } from '@repo/redux/profileSlice';
import { ACCOUNTS_NODE_CSRF_SESSION } from '@repo/config/constants';
import { windowRedirect } from '@repo/config/generalUtils';
import { formatUserDetails, refreshAccessToken } from '@repo/config/authUtils';

export default function useAuth(domain: string, isProtected: boolean) {
  const dispatch = useAppDispatch();
  const [isUserInfoInitialized, setIsUserInfoInitialized] =
    React.useState(false);
  const { accessToken } = useAppSelector(selectProfile);

  /**
   * Handles token refresh and updates the Redux store
   */
  const handleTokenRefresh = async (): Promise<boolean> => {
    try {
      console.warn('No access token found, attempting to refresh...');
      const csrfToken = localStorage.getItem(ACCOUNTS_NODE_CSRF_SESSION);

      if (!csrfToken) {
        console.error('No CSRF token found in localStorage.');
        redirectToSignIn();
        return false;
      }

      const newToken = await refreshAccessToken(csrfToken);

      if (!newToken?.accessToken) {
        console.error('Token refresh failed. No new access token received.');
        redirectToSignIn();
        return false;
      }

      const newUserDetails = formatUserDetails(newToken?.userInfo);

      // Update Redux store
      dispatch(
        setProfile({
          accessToken: newToken.accessToken,
          refreshToken: '',
          domain,
          ...newUserDetails,
        })
      );

      console.info('Token successfully refreshed and user details updated.');
      return true;
    } catch (error) {
      console.error('Error during token refresh:', error);
      redirectToSignIn();
      return false;
    }
  };

  /**
   * Redirect to sign-in with source path as query param
   */
  const redirectToSignIn = () => {
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;
    const source = encodeURIComponent(`${currentPath}${currentQuery}`);
    windowRedirect(`/accounts/auth/sign-in?n=${source}`);
  };

  /**
   * Initializes user details (refreshes token if needed)
   */
  const initializeUserDetails = async () => {
    // Early exit if access token exists
    if (accessToken) {
      setIsUserInfoInitialized(true);
      return;
    }

    const success = await handleTokenRefresh();
    if (!success) {
      return console.warn('Redirecting to sign-in page...');
    }
    setIsUserInfoInitialized(true); // Mark initialization as complete
  };

  /**
   * Effect to initialize user details
   */
  React.useEffect(() => {
    if (!isProtected) {
      setIsUserInfoInitialized(true);
      return;
    }
    initializeUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, domain]);

  return { isUserInfoInitialized };
}
